import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { styled } from '@mui/system';

const BubbleContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  //boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(2),
  color: 'white'
}));

const UpdateBubble = ({ version, name, changes }: { version: string; name: string; changes: string[] }) => {
  return (
    <BubbleContainer>

      <Typography className = "BubbleHeader" variant="h5" component="div" gutterBottom>
        {version} - {name}
      </Typography>

      <Divider sx={{ marginY: 1 }} />

      <Box>
        {changes.map((change, index) => (
          <Typography className = "BubbleBulletsText" key={index} variant="body2" component="div">
            • {change}
          </Typography>
        ))}
      </Box>

    </BubbleContainer>
  );
};

export default UpdateBubble;
