import React from 'react';
import './Updates.css';
import { Box, Container, Typography } from '@mui/material';
// components
import UpdateBubble from './UpdateBubble';

const Updates = () => {
    const updates = [
        //new updates go HERE
      {
        version: '0.2.0 - Aug. 4 \'24',
        name: 'Formatting Polish',
        changes: ['CUSTOM CARD: Text Fields (Name, Type, Cost, Power, Defense, & especially Effect) all reworked and polished.  (This includes parent containers, text bounds, fonts, text sizing and spacing, custom symbol adjustments, and adaptability between Old Frames, New Frames, and Hero Frames.)',
            'CUSTOM CARD: Switched font from PalatinoMed to PalatinoLight. (Will also help accentuate Bold more, as was needed.)',
            'FORM: Added support for "--" and "---" in Card Effect Text (mdash & ndash)',
            'FORM: Border Category split in two -- Vanilla Classes and Talented Classes',
            'FORM: All Border Art lists alphabetized. Token rarity moved from 5th to 1st in list.',
            'WEBSITE: Fonts updates across both pages to Segoe UI',
            'MAINTENANCE: Tons of refactoring behind the scenes'],
      },
      {
        version: '0.1.0 - Jul. 16 \'24',
        name: 'Initial Release',
        changes: ['Website officially goes LIVE on FabCustomCardCreator.com',
            'Is missing a fair bit of art assets',
            'Is good enough, but has plenty of small formatting issues'],
      }
    ];
  
    return (
      <Container component="main">

        <Box className = "LabelBox">
            <Typography className = "LabelText" variant="h2" component="h1" gutterBottom>
            Version History
            </Typography>
        </Box>

        {updates.map((update, index) => (
          <UpdateBubble key={index} version={update.version} name={update.name} changes={update.changes} />
        ))}

      </Container>
    );
  };

export default Updates;