import React from "react";
import { toPng } from "html-to-image";
import { CardFrameData, Pitch, TextSymbol } from "../data/_DataHelpers";

export function categoryDisablesCost(newCategory: string) {
    return (newCategory === "Heroes" || newCategory === "Tokens" || newCategory === "Inventory")
}

export function shouldShowResourceCornerIcon(currentCategory: string, resourceNumber: number, PitchLibrary: Pitch, selectedPitch: string) {
    const isProperCategory = (currentCategory !== "Heroes" && currentCategory !== "Tokens" && currentCategory !== "Inventory");
    return (isProperCategory && PitchLibrary[selectedPitch].numberValue >= resourceNumber);
}

export function shouldShowPowerIcon(currentCategory: string, cardPowerIntellectText: string|null) {
    const isProperCategory = (currentCategory !== "Heroes");
    return (isProperCategory && cardPowerIntellectText && cardPowerIntellectText.length > 0)
}

export function shouldShowDefenseIcon(currentCategory: string, cardDefenseHealthText: string|null) {
    const isProperCategory = (currentCategory !== "Heroes");
    return (isProperCategory && cardDefenseHealthText && cardDefenseHealthText.length > 0)
}
export function usingHeroFrame(selectedBorderCategory: string) {
    return (selectedBorderCategory === "Heroes");
}

export function usingDeckCardFrame(selectedBorderCategory: string) {
    return (selectedBorderCategory !== "Heroes" && selectedBorderCategory !== "Tokens" && selectedBorderCategory !== "Inventory");
}

export function usingOldFrame(FormattedCardFramesLibrary: {[x:string]:{options: CardFrameData[];};}, selectedBorderCategory: string, selectedBorderArt: string): Boolean|undefined {
    const category = FormattedCardFramesLibrary[selectedBorderCategory];
    if (!category) return undefined;

    const borderArt = category.options.find((b) => b.name === selectedBorderArt);
    return borderArt ? borderArt?.oldFrame : undefined;
}

export function getEffectBoxCssClass(FormattedCardFramesLibrary: {[x:string]:{options: CardFrameData[];};}, selectedBorderCategory: string, selectedBorderArt: string): string|undefined {
    if (selectedBorderCategory === "Heroes")
        return "CardPreviewBox-Effect-HeroFrame";
    else if (usingOldFrame(FormattedCardFramesLibrary, selectedBorderCategory, selectedBorderArt))
        return "CardPreviewBox-Effect-OldFrame";
    else
        return "CardPreviewBox-Effect-NewFrame";
}

export function handleEffectTextCustomSymbols(effectText: string, FormattedTextSymbolsLibrary: TextSymbol) {
    const parts = effectText.split(/(\{[dhipr]\}|\<b\>|\<\/b\>|\<i\>|\<\/i\>|\*\s|---|--)/g); // Split by {p}, {d}, {i}, {h}, {r}, <b>, </b>, <i>, </i>, '* ', '---', '--'

    const elements: React.ReactNode[] = [];
    let isBold = false;
    let isItalic = false;

    parts.forEach((part, index) => {
        if (FormattedTextSymbolsLibrary[part]) {
            elements.push(<img key={index} src={FormattedTextSymbolsLibrary[part]} alt={part} style={{ display: 'inline', width: '12px', height: '12px', transform: 'translate(0%, 6%)' }} />);
        } else {
            switch (part) {
                case '<b>':
                    isBold = true;
                    elements.push(<React.Fragment key={index} />);
                    break;
                case '</b>':
                    isBold = false;
                    elements.push(<React.Fragment key={index} />);
                    break;
                case '<i>':
                    isItalic = true;
                    elements.push(<React.Fragment key={index} />);
                    break;
                case '</i>':
                    isItalic = false;
                    elements.push(<React.Fragment key={index} />);
                    break;
                case '* ':
                    elements.push(<span key={index}>&bull; </span>);
                    break;
                case '---':
                    elements.push(<span key={index}>&mdash;</span>);
                    break;
                case '--':
                    elements.push(<span key={index}>&ndash;</span>);
                    break;
                default:
                    if (isBold && isItalic) {
                        elements.push(<b key={index}><i>{part}</i></b>);
                    } else if (isBold) {
                        elements.push(<b key={index}>{part}</b>);
                    } else if (isItalic) {
                        elements.push(<i key={index}>{part}</i>);
                    } else {
                        elements.push(part);
                    }
                    break;
            }
        }
    });

    return <>{elements}</>;
}

export function powerIntellectLabel(selectedBorderCategory: string): string {
    return (selectedBorderCategory === "Heroes" ? "Int" : "Power");
}

export function defenseHealthLabel(selectedBorderCategory: string): string {
    return (selectedBorderCategory === "Heroes" ? "Health" : "Defense");
}

export function handleImageUpload(event: React.ChangeEvent<HTMLInputElement>, setUploadedImage: React.Dispatch<React.SetStateAction<string|null>>, setImageToClear: React.Dispatch<React.SetStateAction<string|null>>): void {
    const file = event.target.files && event.target.files[0]; //ToDo: Understand wtf
    if (file) {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
        setUploadedImage(fileReader.result as string); //ToDo: Understand wtf
        setImageToClear("");
        };
        fileReader.readAsDataURL(file);
    }
}

export function handleImageDownload(cardCompositePreviewContainerRef: React.MutableRefObject<null>, cardNameText: string): void {
    if (cardCompositePreviewContainerRef.current) {
        toPng(cardCompositePreviewContainerRef.current)
        .then((dataUrl) => {
            const link = document.createElement("a");
            link.href = dataUrl;
            link.download = (cardNameText.length > 0 ? `${cardNameText}.png` : "FabCustomCard.png");
            link.click();
        })
        .catch((err) => {
            console.error("Failed to export image", err);
        });
    }
}

export function handleBorderCategoryChanged(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setSelectedBorderCategory: React.Dispatch<React.SetStateAction<string>>,
    setSelectedBorderArt: React.Dispatch<React.SetStateAction<string>>,
    setCardCostText: React.Dispatch<React.SetStateAction<string>>,
    FormattedCardFramesLibrary: {[x: string]: {options: CardFrameData[];};})  {

    setSelectedBorderCategory(event.target.value)
    setSelectedBorderArt(FormattedCardFramesLibrary[event.target.value]?.options[0].name)

    //Update Disabled Values by Category
    if (categoryDisablesCost(event.target.value)) {
        setCardCostText("");
    }
}

export function textFieldChanged_LimitMaxChars(
    inputvalue: string,
    maxChars: number,
    setValueCallback: React.Dispatch<React.SetStateAction<string>>) {

    if (inputvalue.length <= maxChars) {
        setValueCallback(inputvalue);
    }
}
