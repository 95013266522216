import './App.css';
import './components/CardCompositePreview/CardCompositePreview.css';
import React, { useEffect, useRef, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// components
import NavBar from './components/NavBar/NavBar';
// themes
import darkTheme from './themes/DarkTheme';
import CustomCssBaseline from './themes/CustomCssBaseline';
// pages
import Homepage from './pages/Homepage/Homepage';
import Updates from './pages/Updates/Updates';
import BloodKnight from './pages/BloodKnight/BloodKnight';

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CustomCssBaseline />

      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/Updates" element={<Updates />} />
          <Route path="/BloodKnight" element={<BloodKnight />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
