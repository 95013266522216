import './CardCompositePreview.css';
import { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { categoryDisablesCost, shouldShowResourceCornerIcon, shouldShowPowerIcon, shouldShowDefenseIcon, usingOldFrame, handleEffectTextCustomSymbols, getEffectBoxCssClass, usingHeroFrame, usingDeckCardFrame } from '../../functions/UiLogic';
import { Pitch } from "../../data/_DataHelpers";
import CardFramesLibrary from '../../data/CardFramesLibrary.json';
import TextSymbolsLibrary from '../../data/TextSymbolsLibrary.json';
import PitchesLibrary from '../../data/PitchesLibrary.json';
import { formatInternalRef_CardFrames, formatInternalRef_TextSymbols } from "../../functions/DataRefFormatters";

interface CardCompositePreviewProps {
  dynamicBorderImage: string;
  uploadedImage: string|null;
  uploadedImage_SmallerAlt: string|null;
  rarityImage: string;

  selectedBorderCategory: string;
  selectedBorderArt: string;
  selectedPitch: string;

  cardNameText: string;
  cardTypesText: string;
  cardCostText: string;
  cardPowerIntellectText: string;
  cardDefenseHealthText: string;
  cardEffectText: string;
  cardSetCodeText: string;
}

const calculateFontSize = (cardText: string, boxWidth: number|undefined, defaultFontSize: number, minFontSize: number, fontFamily: string, downscaleDeckCards: Boolean, selectedBorderCategory: string): number => {
  if (!boxWidth)
    return defaultFontSize;

  if (downscaleDeckCards && usingDeckCardFrame(selectedBorderCategory))
    defaultFontSize = defaultFontSize -2;
  
  let low = minFontSize;
  let high = defaultFontSize;
  let bestFit = minFontSize;

  while (low <= high) {
    const mid = (low + high) / 2;
    const textWidth = measureTextWidth(cardText, mid, fontFamily);

    if (textWidth <= boxWidth) {
      bestFit = mid;
      low = mid + 0.01;
    } else {
      high = mid - 0.01;
    }
  }

  return bestFit;
};
const measureTextWidth = (text: string, fontSize: number, fontFamily: string) => {
  text += '  ';
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return 0;
  }
  context.font = `${fontSize}px ${fontFamily}`;
  return context.measureText(text).width;
};

function CardCompositePreview(
  {   dynamicBorderImage, uploadedImage, uploadedImage_SmallerAlt, rarityImage,
      selectedBorderCategory, selectedBorderArt, selectedPitch,
      cardNameText, cardTypesText, cardCostText, cardPowerIntellectText, cardDefenseHealthText, cardEffectText, cardSetCodeText, 
  }: CardCompositePreviewProps) {

  //Json Data
  const FormattedCardFramesLibrary = formatInternalRef_CardFrames(CardFramesLibrary);
  const FormattedTextSymbolsLibrary = formatInternalRef_TextSymbols(TextSymbolsLibrary);
  const PitchLibrary: Pitch = PitchesLibrary;

  const [fontSize_Name, setFontSize_Name] = useState<number>(28);
  const refNameText = useRef<HTMLDivElement>(null);
  const [fontSize_Types, setFontSize_Types] = useState<number>(16);
  const refTypesBox = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setFontSize_Name(calculateFontSize(cardNameText, refNameText.current?.offsetWidth, 28, 12, "CardTitles", true, selectedBorderCategory));
    setFontSize_Types(calculateFontSize(cardTypesText, refTypesBox.current?.offsetWidth, 16, 10, "CardTitles", false, selectedBorderCategory));
  }, [cardNameText, cardTypesText, dynamicBorderImage]);

  return(
      <Box>
          <img className='BorderArt-Image'
              src={dynamicBorderImage}
              alt={dynamicBorderImage}
          />

          {uploadedImage && (
            <img className = "CardArtwork-Image"
              src={uploadedImage}
              alt="Uploaded"
            />
          )}

          {uploadedImage_SmallerAlt && (
            <img className = "CardArtwork-Image-SmallerAlt"
              src={uploadedImage_SmallerAlt}
              alt="UploadedSmallerAlt"
            />
          )}

          {!categoryDisablesCost(selectedBorderCategory) && (
            <Box className = "PitchColorStrip-Image" sx={{bgcolor: PitchLibrary[selectedPitch].color }}/>
          )}
          {!categoryDisablesCost(selectedBorderCategory) && (
            <Box className = "PitchCornerBg-Image"/>
          )}

          {shouldShowResourceCornerIcon(selectedBorderCategory, 1, PitchLibrary, selectedPitch) && (
            <img className='Resource-CornerIcon-1'
              src={`${process.env.PUBLIC_URL}/Assets/Icons/Icon_r_Resource.png`}
              alt="{r}"
            />
          )}
          {shouldShowResourceCornerIcon(selectedBorderCategory, 2, PitchLibrary, selectedPitch) && (
            <img className='Resource-CornerIcon-2'
              src={`${process.env.PUBLIC_URL}/Assets/Icons/Icon_r_Resource.png`}
              alt="{r}"
            />
          )}
          {shouldShowResourceCornerIcon(selectedBorderCategory, 3, PitchLibrary, selectedPitch) && (
            <img className='Resource-CornerIcon-3'
              src={`${process.env.PUBLIC_URL}/Assets/Icons/Icon_r_Resource.png`}
              alt="{r}"
            />
          )}

          {shouldShowPowerIcon(selectedBorderCategory, cardPowerIntellectText) && (
            <img className = {`${usingOldFrame(FormattedCardFramesLibrary, selectedBorderCategory, selectedBorderArt) ? 'Power-Icon-OldFrame' : 'Power-Icon-NewFrame'}`}
              src={`${process.env.PUBLIC_URL}/Assets/Icons/Icon_p_Power.png`}
              alt="{p}"
            />
          )}

          {shouldShowDefenseIcon(selectedBorderCategory, cardDefenseHealthText) && (
            <img className = {`${usingOldFrame(FormattedCardFramesLibrary, selectedBorderCategory, selectedBorderArt) ? 'Defense-Icon-OldFrame' : 'Defense-Icon-NewFrame'}`}
              src={`${process.env.PUBLIC_URL}/Assets/Icons/Icon_d_Defense.png`}
              alt="{d}"
            />
          )}

          <Typography className = {`${usingDeckCardFrame(selectedBorderCategory) ? 'CardPreviewText-Name-DeckCards' : 'CardPreviewText-Name-NonDeckCards'}`}
            ref = {refNameText}
            fontFamily="CardTitles"
            component="div"
            style={{ fontSize: `${fontSize_Name}px`}}
          >
            {cardNameText}
          </Typography>

          <Box className = "CardPreviewBox-Types"
            ref = {refTypesBox}
          >
          <Typography className = "CardPreviewText-Types"
            fontFamily="CardTitles"
            component="div"
            style={{ fontSize: `${fontSize_Types}px`}}
          >
            {cardTypesText}
          </Typography>
          </Box>

          <Box className = "CardPreviewBox-Cost">
            <Typography className = "CardPreviewText-Cost"
              fontFamily="CardTexts"
              component="div"
            >
              {cardCostText}
            </Typography>
          </Box>

          <Box className = {`CardPreviewBox-PowerIntellect ${usingOldFrame(FormattedCardFramesLibrary, selectedBorderCategory, selectedBorderArt) ? 'CardPreviewBox-PowerIntellect-OldFrame' : 'CardPreviewBox-PowerIntellect-NewFrame'}`}
          >
            <Typography className = "CardPreviewText-PowerIntellect"
              fontFamily="CardTexts"
              component="div"
            >
              {cardPowerIntellectText}
            </Typography>
          </Box>

          <Box className = {`CardPreviewBox-DefenseHealth ${usingOldFrame(FormattedCardFramesLibrary, selectedBorderCategory, selectedBorderArt) ? 'CardPreviewBox-DefenseHealth-OldFrame' : 'CardPreviewBox-DefenseHealth-NewFrame'}`}
          >
            <Typography className = "CardPreviewText-DefenseHealth"
              fontFamily="CardTexts"
              component="div"
            >
              {cardDefenseHealthText}
            </Typography>
          </Box>

          <Box className = {`${getEffectBoxCssClass(FormattedCardFramesLibrary, selectedBorderCategory, selectedBorderArt)}`}
          >
            <Typography className = {`${usingHeroFrame(selectedBorderCategory) ? 'CardPreviewText-Effect-HeroFrame' : 'CardPreviewText-Effect-NonheroFrame'}`}
              fontFamily="CardTexts"
              component="div"
              align='center'
              paragraph
            >
              {handleEffectTextCustomSymbols(cardEffectText, FormattedTextSymbolsLibrary)}
            </Typography>
          </Box>

          <Box className = {`BottomOfCard-Info-Box ${usingOldFrame(FormattedCardFramesLibrary, selectedBorderCategory, selectedBorderArt) ? 'BottomOfCard-Info-Box-OldFrame' : 'BottomOfCard-Info-Box-NewFrame'}`}>
              <img className='BottomOfCard-Rarity-Image'
                  src={rarityImage}
                  alt={rarityImage}
              />

              <Typography className = "BottomOfCard-Language"
                  fontFamily="CardBottoms"
                  component="div"
              >
                  EN |
              </Typography>

              <Typography className = "BottomOfCard-SetCode"
                  fontFamily="CardBottoms"
                  component="div"
              >
                  {cardSetCodeText}
              </Typography>

              <Typography className = "BottomOfCard-Static-AdNames"
                  fontFamily="CardBottoms"
                  component="div"
              >
                  FabCustomCardCreator.com | FaB Life
              </Typography>
          </Box>
      </Box>
  );
}

export default CardCompositePreview;