import '../../components/CardCompositePreview/CardCompositePreview.css';
import React, { useEffect, useRef, useState } from 'react';
import { Container, Box, ThemeProvider } from '@mui/material';
// data
import CardFramesLibrary from '../../data/CardFramesLibrary.json';
import { formatInternalRef_CardFrames } from '../../functions/DataRefFormatters';
// components
import PropertiesForm from '../../components/PropertiesForm/PropertiesForm';
import UploadDownloadButtons from '../../components/UploadDownloadButtons/UploadDownloadButtons';
import CardCompositePreview from '../../components/CardCompositePreview/CardCompositePreview';

function Homepage() {
  // Json Data
  const FormattedCardFramesLibrary = formatInternalRef_CardFrames(CardFramesLibrary);
  // Card Properties
  const [cardNameText, setCardNameText] = useState<string>("");
  const [cardTypesText, setCardTypesText] = useState<string>("");
  const [cardCostText, setCardCostText] = useState<string>("");
  const [cardPowerIntellectText, setCardPowerIntellectText] = useState<string>("");
  const [cardDefenseHealthText, setCardDefenseHealthText] = useState<string>("");
  const [cardEffectText, setCardEffectText] = useState<string>("");
  const [cardSetCodeText, setCardSetCodeText] = useState<string>("");
  const [dynamicBorderImage, setDynamicBorderImage] = useState<string>("");
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [uploadedImage_SmallerAlt, setuploadedImage_SmallerAlt] = useState<string | null>(null);
  const [rarityImage, setRarityImage] = useState<string>(`${process.env.PUBLIC_URL}/Assets/Icons/Rarity_Token.png`);
  // Runtime Choices
  const [selectedBorderCategory, setSelectedBorderCategory] = useState<string>("Heroes");
  const [selectedBorderArt, setSelectedBorderArt] = useState<string>("Aria");
  const [selectedPitch, setSelectedPitch] = useState<string>("Red");
  // Basic Refs
  const cardCompositePreviewContainerRef = useRef(null);
  
  /* Helper Functions (TO MOVE TO DEDICATED FILE WITH UI PROPERTIES COMPONENT) */
  // ToDo: Understand what this is doing and how this works -- for setting Dynamic Border Art Image based on dropdown values
  useEffect(() => {
    if (Boolean(selectedBorderCategory) === false || Boolean(selectedBorderArt) === false)
      return;

    const cardImage = FormattedCardFramesLibrary[selectedBorderCategory]?.options.find((option) => option.name === selectedBorderArt)?.image;
    if (cardImage)
      setDynamicBorderImage(cardImage);
  }, [selectedBorderArt, selectedBorderCategory]);

  return (
      <Container component="main">
        <Box id = "TwoColumns" display={"flex"} flexDirection={"row"} width={"5s0vw"} height={"100vh"}>
          <Box id = "Column-1"
            width={"50%"}
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
          >
            <PropertiesForm 
              cardNameText={cardNameText} setCardNameText={setCardNameText}
              cardTypesText={cardTypesText} setCardTypesText={setCardTypesText}
              selectedBorderCategory={selectedBorderCategory} setSelectedBorderCategory={setSelectedBorderCategory}
              selectedBorderArt={selectedBorderArt} setSelectedBorderArt={setSelectedBorderArt}
              selectedPitch={selectedPitch} setSelectedPitch={setSelectedPitch}
              cardCostText={cardCostText} setCardCostText={setCardCostText}
              cardPowerIntellectText={cardPowerIntellectText} setCardPowerIntellectText={setCardPowerIntellectText}
              cardDefenseHealthText={cardDefenseHealthText} setCardDefenseHealthText={setCardDefenseHealthText}
              cardEffectText={cardEffectText} setCardEffectText={setCardEffectText}
              setRarityImage={setRarityImage}
              cardSetCodeText={cardSetCodeText} setCardSetCodeText={setCardSetCodeText}
            />

            <UploadDownloadButtons 
              cardNameText={cardNameText}
              cardCompositePreviewContainerRef={cardCompositePreviewContainerRef}
              setUploadedImage={setUploadedImage}
              setUploadedImage_SmallerAlt={setuploadedImage_SmallerAlt}
            />
          </Box>

          <Box id = "Column-2"
            width={"50%"}
            height={"100%"}
            display={"flex"}
            alignItems={"left"}
            flexDirection={"column"}
            paddingLeft={"32px"}
          >
            <Box className = "Card-Composite-Preview"
              ref = {cardCompositePreviewContainerRef}
            >
              <CardCompositePreview
                dynamicBorderImage={dynamicBorderImage} 
                uploadedImage={uploadedImage}
                uploadedImage_SmallerAlt={uploadedImage_SmallerAlt}
                rarityImage={rarityImage}

                selectedBorderCategory={selectedBorderCategory}
                selectedBorderArt={selectedBorderArt}
                selectedPitch={selectedPitch}

                cardNameText={cardNameText}
                cardTypesText={cardTypesText}
                cardCostText={cardCostText}
                cardPowerIntellectText={cardPowerIntellectText}
                cardDefenseHealthText={cardDefenseHealthText}
                cardEffectText={cardEffectText}
                cardSetCodeText={cardSetCodeText}
              />
            </Box>
          </Box>
        </Box>
      </Container>
  );
}

export default Homepage;
