import { GlobalStyles } from '@mui/material';

const CustomCssBaseline = () => (
  <GlobalStyles
    styles={{
      body: {
        margin: 0,
        backgroundColor: '#121212', // Dark theme background color
      },
      // Add any other global styles you need here
    }}
  />
);

export default CustomCssBaseline;
