import { CardFrame, RarityIcon, TextSymbol } from "../data/_DataHelpers";

export function formatInternalRef_CardFrames(obj: CardFrame) {
    const newObj = { ...obj };

    Object.keys(newObj).forEach((key) => {
        if (Array.isArray(newObj[key].options)) {
        newObj[key].options = newObj[key]?.options.map((item) => {
            return {
            ...item,
            image: `${process.env.PUBLIC_URL}${item.image}`,
            };
        });
        }
    });

    return newObj;
}

export function formatInternalRef_RarityIcons(assets: RarityIcon[]): RarityIcon[] {
    return assets.map((asset) => ({
        ...asset,
        image: `${process.env.PUBLIC_URL}${asset.image}`,
    }))
}

export function formatInternalRef_TextSymbols(symbols: TextSymbol): TextSymbol {
    const formattedSymbols: TextSymbol = {};

    for (const key in symbols) {
        if (symbols.hasOwnProperty(key)) {
        formattedSymbols[key] = `${process.env.PUBLIC_URL}${symbols[key]}`;
        }
    }

    return formattedSymbols;
}
