import './UploadDownloadButtons.css';
import { Box, Button, Typography } from '@mui/material';
import { handleImageUpload, handleImageDownload } from '../../functions/UiLogic';
import { FileUpload, SaveAlt } from "@mui/icons-material";
import VisuallyHiddenInput from "../wrappers/VisuallyHiddenInput";

interface UploadDownloadButtonsProps {
    cardNameText: string,
    cardCompositePreviewContainerRef: React.MutableRefObject<null>;
    setUploadedImage: React.Dispatch<React.SetStateAction<string|null>>;
    setUploadedImage_SmallerAlt: React.Dispatch<React.SetStateAction<string|null>>;
}

function UploadDownloadButtons(
    {
        cardNameText, cardCompositePreviewContainerRef,
        setUploadedImage, setUploadedImage_SmallerAlt
    }: UploadDownloadButtonsProps) {

    const handleUploadClick = (event: React.ChangeEvent<HTMLInputElement>) => { handleImageUpload(event, setUploadedImage, setUploadedImage_SmallerAlt); };
    const handleUploadClick_SmallerAlt = (event: React.ChangeEvent<HTMLInputElement>) => { handleImageUpload(event, setUploadedImage_SmallerAlt, setUploadedImage); };
    const handleDownloadClick = () => { handleImageDownload(cardCompositePreviewContainerRef, cardNameText); };

    return (
        <Box id = "Upload-Download-Butons"
            display={"flex"}
            flexDirection={"column"}
            alignItems={"left"}
        >
            <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"left"}
            >
                <Button id = "Upload-Art-Button"
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<FileUpload />}
                    sx={{margin:1}}
                >
                    Change Art (Portrait)
                    <VisuallyHiddenInput id = "File-Upload-Element"
                        type="file"
                        accept='.jpg, .jpeg, .png'
                        onChange={handleUploadClick}
                    />
                </Button>
                <Button id = "Upload-Art-Button"
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<FileUpload />}
                    sx={{margin:1}}
                >
                    Change Art (Square)
                    <VisuallyHiddenInput id = "File-Upload-Element"
                        type="file"
                        accept='.jpg, .jpeg, .png'
                        onChange={handleUploadClick_SmallerAlt}
                    />
                </Button>
                <Typography className = "ChangeArtHelperText-Portrait"
                >
                    Portrait Art Ratio: 1.4:1 | Easy alternative: 3:2<br/>
                    Square Art Ratio: 1:1 | Not recommended for Heroes.
                </Typography>
            </Box>

            <Button id = "Download-Image-Button"
                variant='contained'
                startIcon={<SaveAlt />}
                onClick={handleDownloadClick}
                sx={{margin:1}}
            >
                Download Custom Card
            </Button>
        </Box>
    );
}

export default UploadDownloadButtons;