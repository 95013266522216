import { Box, MenuItem, TextField, Typography } from "@mui/material";
import { Pitch } from "../../data/_DataHelpers";
import { categoryDisablesCost, defenseHealthLabel, powerIntellectLabel, handleBorderCategoryChanged, textFieldChanged_LimitMaxChars } from '../../functions/UiLogic';
import CardFramesLibrary from '../../data/CardFramesLibrary.json';
import RarityIconsLibrary from '../../data/RarityIconsLibrary.json';
import PitchesLibrary from '../../data/PitchesLibrary.json';
import { formatInternalRef_CardFrames, formatInternalRef_RarityIcons } from "../../functions/DataRefFormatters";


interface PropertiesFormProps {
    cardNameText: string;
    setCardNameText: React.Dispatch<React.SetStateAction<string>>;
    cardTypesText: string;
    setCardTypesText: React.Dispatch<React.SetStateAction<string>>;
    selectedBorderCategory: string;
    setSelectedBorderCategory: React.Dispatch<React.SetStateAction<string>>;
    selectedBorderArt: string;
    setSelectedBorderArt: React.Dispatch<React.SetStateAction<string>>;
    selectedPitch: string;
    setSelectedPitch: React.Dispatch<React.SetStateAction<string>>;
    cardCostText: string;
    setCardCostText: React.Dispatch<React.SetStateAction<string>>;
    cardPowerIntellectText: string;
    setCardPowerIntellectText: React.Dispatch<React.SetStateAction<string>>;
    cardDefenseHealthText: string;
    setCardDefenseHealthText: React.Dispatch<React.SetStateAction<string>>;
    cardEffectText: string;
    setCardEffectText: React.Dispatch<React.SetStateAction<string>>;
    setRarityImage: React.Dispatch<React.SetStateAction<string>>;
    cardSetCodeText: string;
    setCardSetCodeText: React.Dispatch<React.SetStateAction<string>>;
}

function PropertiesForm(
    {   cardNameText, setCardNameText,
        cardTypesText, setCardTypesText,
        selectedBorderCategory, setSelectedBorderCategory,
        selectedBorderArt, setSelectedBorderArt,
        selectedPitch, setSelectedPitch, 
        cardCostText, setCardCostText,
        cardPowerIntellectText, setCardPowerIntellectText,
        cardDefenseHealthText, setCardDefenseHealthText,
        cardEffectText, setCardEffectText,
        setRarityImage,
        cardSetCodeText, setCardSetCodeText
    }: PropertiesFormProps) {

    // Json Data
    const FormattedCardFramesLibrary = formatInternalRef_CardFrames(CardFramesLibrary);
    const FormattedRarityIconsLibrary = formatInternalRef_RarityIcons(RarityIconsLibrary);
    const PitchLibrary: Pitch = PitchesLibrary;
    const allPitches = Object.keys(PitchesLibrary);
    // Basic Refs
    const allBorderCategories = Object.keys(FormattedCardFramesLibrary);

    return (
        <Box id = "Properties-Form"
            display={"flex"}
            flexDirection={"column"}
            alignItems={"left"}
        >
            <TextField id = "Card-Name-Inputfield"
                value={cardNameText}
                label="Name"
                variant="outlined"
                onChange={(event) => setCardNameText(event.target.value)}
                sx={{margin:1}}
            >
            </TextField>

            <TextField id = "Card-Types-Inputfield"
                value={cardTypesText}
                label="Types"
                variant="outlined"
                onChange={(event) => setCardTypesText(event.target.value)}
                sx={{margin:1}}
            >
            </TextField>

            <TextField id = "Border-Category-Dropdown"
                select
                label="Border Category"
                value={selectedBorderCategory}
                onChange={(event) => handleBorderCategoryChanged(event, setSelectedBorderCategory, setSelectedBorderArt, setCardCostText, FormattedCardFramesLibrary)}
                sx={{
                margin:1,
                minWidth:"200px"
                }}
            >
                {allBorderCategories.map((categoryName) => (
                <MenuItem key={categoryName} value={categoryName}>
                    {categoryName}
                </MenuItem>
                ))}
            </TextField>

            <TextField id = "Border-Art-Dropdown"
              select
              label="Border Art"
              value={selectedBorderArt}
              onChange={(event) => setSelectedBorderArt(event.target.value)}
              sx={{
                margin:1,
                minWidth:"200px"
              }}
            >
              { //Map Menu Items from selected category
              Boolean(selectedBorderCategory)
                ? FormattedCardFramesLibrary[selectedBorderCategory]?.options.map(
                    (borderType) => {
                      return (
                        <MenuItem
                          key={borderType.name}
                          value={borderType.name}
                          defaultValue={FormattedCardFramesLibrary[selectedBorderCategory].options[0].name
                          }
                        >
                          {borderType.name}
                        </MenuItem>
                      );
                    }
                  )
                //No category selected, Map Menu Items from first choice
                : FormattedCardFramesLibrary[0]?.options.map((borderType) => (
                    <MenuItem
                      key={borderType.name}
                      value={borderType.name}
                    >
                      {borderType.name}
                    </MenuItem>
                  ))}
            </TextField>

            <TextField id = "Pitch-Dropdown"
              select
              label="Pitch Color"
              value={selectedPitch}
              onChange={(event) => setSelectedPitch(event.target.value)}
              disabled={categoryDisablesCost(selectedBorderCategory)}
              sx={{
                margin:1,
                width:"200px"
              }}
            >
              {allPitches.map((pitch) => (
                <MenuItem key={pitch} value={pitch}>
                  {PitchLibrary[pitch].name}
                </MenuItem>
              ))}
            </TextField>

            <TextField id = "Card-Cost-Inputfield"
              value={cardCostText}
              label="Cost"
              variant="outlined"
              disabled={categoryDisablesCost(selectedBorderCategory)}
              onChange={(event) => textFieldChanged_LimitMaxChars(event.target.value, 2, setCardCostText)}
              sx = {{
                margin:1,
                width:"80px"
              }}
            >
            </TextField>

            <TextField id = "Card-PowerIntellect-Inputfield"
              value={cardPowerIntellectText}
              label={powerIntellectLabel(selectedBorderCategory)}
              variant="outlined"
              onChange={(event) => textFieldChanged_LimitMaxChars(event.target.value, 2, setCardPowerIntellectText)}
              sx = {{
                margin:1,
                width:"80px"
              }}
            >
            </TextField>

            <TextField id = "Card-DefenseHealth-Inputfield"
              value={cardDefenseHealthText}
              label={defenseHealthLabel(selectedBorderCategory)}
              variant="outlined"
              onChange={(event) => textFieldChanged_LimitMaxChars(event.target.value, 2, setCardDefenseHealthText)}
              sx = {{
                margin:1,
                width:"80px"
              }}
            >
            </TextField>

            <TextField id = "Card-Effect-Inputfield"
              value={cardEffectText}
              label="Effect"
              variant="outlined"
              helperText="Symbols: {p}, {d}, {r}, {h}, {i} | Formatting: <b></b>, <i></i>, *, --"
              onChange={(event) => setCardEffectText(event.target.value)}
              multiline
              sx={{margin:1}}
            >
            </TextField>

            <TextField id = "Card-Rarity-Dropdown"
              select
              label="Rarity"
              defaultValue={FormattedRarityIconsLibrary[0].name}
              onChange={(event) => {
                const selectedRarity = FormattedRarityIconsLibrary.find((r) => r.name === event.target.value);
                if (selectedRarity) 
                  return setRarityImage(selectedRarity.image);
              }}
              sx={{
                margin:1,
                width:"200px"
              }}
            >
              {FormattedRarityIconsLibrary.map((rarity) => (
                <MenuItem key={rarity.name} value={rarity.name}>
                  {rarity.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField id = "Card-SetCode-Inputfield"
              value={cardSetCodeText}
              label="Set Code"
              variant="outlined"
              onChange={(event) => textFieldChanged_LimitMaxChars(event.target.value, 6, setCardSetCodeText)}
              sx={{margin:1, width:'120px'}}
            >
            </TextField>
        </Box>
    );
}

export default PropertiesForm;