import './BloodKnight.css';
import React from 'react';
import { Box, Container, Typography } from '@mui/material';
// components

function BloodKnight() {
    return (
        <Container component="main">

            <Box className = "HeroBannerBox">

                <img className = "HeroBannerImg" src='../../Assets/PageAssets/BloodKnight/ViladioHeroBanner_LowRes.jpg' />
                
                <Typography className = "HeroBannerNameText">
                Lord Viladio
                </Typography>
            </Box>

        </Container>
    );
}

export default BloodKnight;